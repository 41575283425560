import React from "react";
import Link from "gatsby-plugin-transition-link";

const Button = ({
  className,
  href,
  outboundLink,
  modal,
  onClick,
  type,
  altStyle,
  text,
}) => {
  const Tag = href ? Link : "button";
  const target = outboundLink && "_blank";
  const rel = outboundLink && "noopener noreferrer";
  let link = true;

  href && (link = !href.includes("tel:") && !href.includes("mailto:"));

  return (
    <Tag
      className={`group relative inline-flex font-display text-xl font-medium no-underline ${
        altStyle === 2
          ? "text-blue group-hover:text-yellow"
          : altStyle === 3
          ? ""
          : "text-seafoam hover:text-yellow group-hover:text-yellow"
      } ${className}`}
      {...(link ? { to: href } : { href: href })}
      target={target}
      rel={rel}
      data-modal-open={modal}
      onClick={onClick}
      type={type}
      alt-style={altStyle}
    >
      <div className="relative">
        {text}
        <span
          className={`absolute -bottom-2 left-auto right-0 h-0.5 w-0 transition-all duration-500 ease-in-out group-hover:left-0 group-hover:right-auto group-hover:w-full ${
            altStyle === 2 ? "" : altStyle === 3 ? "" : "bg-yellow"
          }`}
        ></span>
      </div>
    </Tag>
  );
};

export default Button;
